<template>
    <div class="flex-image-container">
        <div
            v-show="!imagem"
            @dragover.prevent="handleDragOver"
            @dragleave.prevent="handleDragLeave"
            @dragenter.prevent="handleDragOver"
            @drop="handleDrop"
            :class="{'drag-over': isDragging}"
        >
            <label
                :for="`image-${key}`"
                class="btn-image ma-2"
            >
                <v-icon>mdi-camera</v-icon>
            </label>
            <input
                type="file"
                accept="image/*"
                :ref="`image-${key}`"
                :id="`image-${key}`"
                :name="`image-${key}`"
                @input="uploader"
            >
        </div>
        <div style="display: flex; align-items: center;">
            <v-img
                class="ma-1"
                contain
                max-width="50"
                v-if="getUrl()"
                :src="getUrl()"
                style="border-radius: 2px;"
            />
            <v-btn
                color="error"
                class="white--text"
                v-if="getUrl()"
                @click.native="clear"
                x-small
            >
                Excluir
            </v-btn>
        </div>
    </div>
</template>

<script>
import { resize } from '@/utils/image';

export default {
    name: 'ImageInputInline',

    props: ['value'],

    data: () => ({
        imagem: null,
        key: null,
        isDragging: false,
    }),

    watch: {
        value(v) {
            this.setImagem(v);
        },
    },

    created() {
        this.key = this.randomKeyGenerate();
    },

    mounted() {
        this.setImagem(this.value);
    },

    methods: {
        setImagem(value) {
            if (!value?.url && !value?.length) {
                this.imagem = null;
                this.$refs[`image-${this.key}`].value = null;
                return;
            }
            value?.length && (this.imagem = value.pop());
            value?.url && (this.imagem = value);
        },

        clear() {
            this.imagem = null;
            this.$refs[`image-${this.key}`].value = null;
            this.$emit('input', null);
        },

        getUrl() {
            if (!this.imagem?.url) {
                return '';
            }

            const url = this.imagem.tmp
                ? `${this.$urlAPI}/files/tmp/${this.imagem.url}`
                : `${this.$urlAPI}/storage/${this.imagem.url}`;

            return url;
        },

        async uploader(e) {
            const files = e.target.files || e.dataTransfer.files;

            if (files.length === 0) return;

            try {
                const urlUpload = `${this.$urlAPI}/files/upload`;
                const file = files[0];
                const isGif = file.type === 'image/gif';

                const blob = isGif ? file : await resize(file, 400);

                const body = new FormData();
                body.append('file', blob);
                const resp = await fetch(urlUpload, {
                    method: 'POST',
                    headers: {
                        authorization: `Bearer ${sessionStorage.getItem('token')}`,
                    },
                    body,
                });

                const name = file.name.split('.');
                name.pop();

                const dataResp = await resp.json();

                this.$emit('input', {
                    descricao: name.join('.'),
                    file: file,
                    fileName: file.name,
                    tmp: true,
                    url: dataResp.path,
                });
            } finally {
                //
            }
        },

        handleDrop(event) {
            event.preventDefault();
            this.handleDragLeave();
            this.uploader(event);
        },

        handleDragOver() {
            this.isDragging = true;
        },

        handleDragLeave() {
            this.isDragging = false;
        },

        randomKeyGenerate() {
            let caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let key = '';
            for (let i = 0; i < 8; i++) {
                key += caracteres.charAt(Math.floor(Math.random() * caracteres.length));
            }
            return key;
        },
    }
}
</script>

<style scoped>
input[type="file"] {
    display: none;
}

label {
    width: 60px;
    height: 20px;
    text-transform: uppercase;
    font-size: 10px;
    text-align: center;
    display: block;
    margin-top: 25px;
    cursor: pointer;
}

.btn-image {
    border-radius: 5px;
}

.flex-image-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.drag-over {
    border: 2px dashed #000;
}
</style>
