<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #e0e0e0;">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title v-show="$vuetify.breakpoint.smAndUp">
                        Cadastro Rápido de Complementos
                    </v-toolbar-title>
                    <v-spacer/>
                    <!-- <v-text-field
                        dense
                        clearable
                        outlined
                        single-line
                        hide-details
                        v-model="search"
                        label="Pesquise.."
                        append-icon="mdi-magnify"
                    /> -->
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text :style="`height: ${$vuetify.breakpoint.height - 220}px; overflow-x: auto;`">
                        <v-text-field
                            v-model="tableData"
                            @paste="handlePaste"
                            label="Colar Informações da tabela"
                            clearable
                            outlined
                            dense
                            hide-details
                        />
                        <v-divider class="my-4"/>
                        <v-row 
                            v-for="(complemento, key) in complementos" 
                            :key="key" 
                            class="justify-center align-center"
                            style="border: 1px solid #ccc; margin: 5px 0; border-radius: 5px; padding: 5px;"
                        >
                            <v-col>
                                <v-text-field
                                    label="Nome do Complemento"
                                    v-model="complemento.descricao"
                                    outlined
                                    dense
                                    hide-details
                                />
                            </v-col>
                            <v-col>
                                <v-textarea
                                    clearable
                                    clear-icon="mdi-backspace-outline"
                                    v-model="complemento.detalhes"
                                    label="Detalhes/Ingredientes"
                                    rows="1"
                                    outlined
                                    dense
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="2">
                                <v-text-field
                                    label="Preço"
                                    prefix="R$"
                                    v-model="complemento.valor"
                                    placeholder="0.00"
                                    v-mask="['#.##','##.##','###.##','####.##','#####.##']"
                                    outlined
                                    dense
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="2">
                                <v-text-field
                                    label="Categoria"
                                    v-model="complemento.categoria"
                                    outlined
                                    dense
                                    hide-details
                                />
                                <!-- <v-text-field
                                    label="Cód. Categoria"
                                    v-model="complemento.categoria_id"
                                    v-mask="['######']"
                                    outlined
                                    dense
                                    hide-details
                                /> -->
                            </v-col>
                            <v-col cols="2">
                                <ImageInputInline ref="imagem" v-model="complemento.imagens" />
                            </v-col>
                            <v-col cols="1">
                                <v-btn @click="deleteRow(key)" color="error" icon>
                                    <v-icon>mdi-delete</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-btn color="primary" class="mt-4" @click="addRow">
                            <v-icon>mdi-plus</v-icon>&nbsp;Adicionar linha
                        </v-btn>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="cancelar" large color="warning" text>Cancelar</v-btn>
                        <v-spacer />
                        <v-btn color="success" large @click="salvar" :loading="loading">
                            <v-icon>mdi-content-save</v-icon>&nbsp;Salvar {{ complementos.length }} Produtos
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import ImageInputInline from '@/components/ImageInputInline';
import { mapState, mapMutations } from 'vuex';

export default {
    name: 'CadastroRapidoComplementos',

    components: {
        BaseContainer,
        ImageInputInline,
    },

    data: () => ({
        search: '',
        loading: false,
        complementos: [],
        tableData: '',
    }),

    computed: {
        ...mapState([
            'usuario',
            'configuracoes',
        ]),
    },

    methods: {
        ...mapMutations({
            setDashboardInitialized: 'setDashboardInitialized',
        }),

        handlePaste(event) {
            const clipboardData = event.clipboardData || window.clipboardData;
            const pastedData = clipboardData.getData('Text');
            const rows = pastedData.split('\n');

            rows.forEach(row => {
                if (!row.trim()) {
                    return;
                }
                const [descricao, detalhes, valor, categoria] = row.split('\t');
                const produto = { descricao, detalhes, valor, categoria };
                this.complementos.push(produto);
            });
        },

        addRow() {
            this.complementos.push({ });
        },

        cancelar() {
            if (!confirm('Tem certeza que deseja limpar todos os campos?')) {
                return;
            }
            this.complementos = [];
        },

        deleteRow(index) {
            this.complementos.splice(index, 1);
        },

        salvar() {
            this.loading = true;
            const data = { complementos: this.complementos };
            this.$http.post('complementos/save-many', data).then(resp => {
                if (resp.data.type === 'warning') {
                    this.notify(resp.data.msg, 'warning');
                    return;
                }
                this.notify('Salvo com sucesso!');
                this.complementos = [];
                this.tableData = '';
                this.setDashboardInitialized(false);
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.loading = false));
        },
    },
}
</script>

<style scoped>
.col {
    padding: 2px;
    vertical-align: center;
}
</style>
